.App{
  background-color: black;
}

*{
  margin: 0px;
  padding: 0px;
 }

 body{
  font-family: 'Raleway', sans-serif;
  
 }
 h1,h2,h3,h4,h5{
   font-family: 'Raleway', sans-serif;
}
p{
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5em;
  letter-spacing: .05em;
  font-weight: 300;
}
h1{
  font-family: 'Raleway', sans-serif;
  font-size: 2.5rem!important;
  font-weight: 500!important;
}
 a{
   color: #fff!important;
   text-decoration: none!important;
   padding-left: 2rem;
   padding-right: 1rem; 
   font-weight: 100; 
  }
.is-active{
   color: rgb(255, 255, 255);
  font-weight: 800;
}
a:hover{
  color:rgb(228, 218, 218)!important;
  font-weight: 600;
  
}
.card:hover{
  transition: all .2s ease-in-out;
}